import React, { useState, useEffect } from 'react';
import { ScrollView, View, Text, Pressable, Modal } from 'react-native';
import { useToast } from 'react-native-toast-notifications';
import { useSync } from '../../Providers/SyncProvider';
import DynamicTextBox from '../DynamicControls/DynamicTextBox';
import DynamicRecipients from '../DynamicControls/DynamicRecipients';
import DynamicAutocomplete from '../DynamicControls/DynamicAutocomplete';
import DynamicBundleLinkRecord from '../DynamicControls/DynamicBundleLinkRecord';
import Icon from '../Shared/Icon';
import LoadingSpinner from '../Shared/LoadingSpinner';
import { ObjectId, UUID } from 'bson';
import { MongoIdSqlId } from '../../Types/DtoTypes';
import {
  SubmissionBundle,
  SubmissionBundle_submissions,
} from '../../Models/RealmModels/SubmissionBundle';
import { ListDataTypes } from '../../Constants/ListDataTypes';
import { CommonStyleSheet } from '../../Styles/Shared/CommonStyles';
import { DynamicPageStyleSheet } from '../../Styles/DynamicPageStyles';
import Colors from '../../Styles/Shared/Colors';
import { SubmissionStatuses } from '../../Constants/SubmissionStatuses';
import {
  FORM_TYPE,
  METADATA_KEYS,
  PERMISSIONS,
} from '../../Constants/AppConstants';
import { usePermission } from '../../Providers/PermissionProvider';
import { AxiosRequestConfig } from 'axios';
import ApiService from '../../Services/ApiService';
import { ControlsStyleSheet } from '../../Styles/Shared/Controls';
import DynamicAttachments from '../DynamicControls/DynamicAttachments';
import DynamicAddRecord from '../DynamicControls/DynamicAddRecord';
import { TemplateTypes } from '../../Constants/TemplateCategoryTypes';
import DynamicCheckBox from '../DynamicControls/DynamicCheckBox';
import DynamicSegmentedControl from '../DynamicControls/DynamicSegmentedControl';
import DynamicPDF from '../DynamicForm/DynamicPDF';
import DynamicBundlePDF from '../DynamicForm/DynamicBundlePDF';
import { Submission } from '../../Models/RealmModels/Submission';
import { DynamicAttachment } from '../../Types/ControlTypes';

type EditSubmissionBundleProps = {
  navigation: any;
  bundleId?: string;
};

const EditSubmissionBundle = (
  props: EditSubmissionBundleProps,
): React.ReactElement => {
  const {
    submissions,
    getSubmission,
    getSubmissionBundles,
    upsertSubmissionBundle,
    deleteSubmissionBundle,
    getOrganisations,
    getPeople,
    getOrgRecipients,
    getOrgId,
    getRealmApp,
    getTemplates,
    getTemplateVersions,
    getSubmissionTrainings,
  } = useSync();
  const toast = useToast();

  const [bundle, setBundle] = useState<SubmissionBundle | undefined>(undefined);
  const [SQLServerId, setSQLServerId] = useState('');
  const [isExternal, setIsExternal] = useState(false);
  const [externalOrgValue, setExternalOrgValue] = useState('');

  const [nameError, setNameError] = useState(true);
  const [documentsError, setDocumentsError] = useState('');
  const [showErrors, setShowErrors] = useState(false);
  const { hasPermission } = usePermission();
  const [canSubmitExternal, setCanSubmitExternal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [auditError, setAuditError] = useState('');
  const [submissionError, setSubmissionError] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isShowingPDFModal, setIsShowingPDFModal] = useState(false);
  const [autoDownload, setAutoDownload] = useState(false);

  const [isShowingActionsModal, setIsShowingActionsModal] = useState(false);

  useEffect(() => {
    let submitExternal = hasPermission(PERMISSIONS.BUNDLE_EXTERNAL_SUBMISSION);
    setCanSubmitExternal(submitExternal);
  }, []);

  useEffect(() => {
    const loadBundle = async () => {
      let realmBundle = (await getSubmissionBundles()).find(s =>
        s._id.equals(props.bundleId!),
      );

      if (!realmBundle) {
        goBack();
        return;
      }

      setNameError(false);

      let orgId = getOrgId();
      if (realmBundle.partition !== orgId) setIsExternal(true);

      let externalOrg = realmBundle.externalOrgId;
      if (externalOrg) {
        let orgArray = await getOrganisations();
        let orgObj = orgArray.find(o => o.SQLServerId === externalOrg);
        if (orgObj)
          setExternalOrgValue(
            JSON.stringify({
              mongoId: orgObj._id.toHexString(),
              label: orgObj.name + (!orgObj.isActive ? ' - Inactive' : ''),
              SQLServerId: orgObj.SQLServerId,
            } as MongoIdSqlId),
          );
      }

      setBundle(realmBundle);
      setSQLServerId(realmBundle.SQLServerId ?? new UUID().toHexString());
      setIsLoading(false);
    };

    loadBundle();
  }, [props.bundleId]);

  useEffect(() => {
    if (autoDownload) setAutoDownload(false);
  }, [autoDownload]);

  const goBack = () => {
    props.navigation.pop();
  };

  const showPDFModal = () => {
    setIsShowingActionsModal(false);
    setIsShowingPDFModal(true);
  };

  async function onChange(
    propName: string,
    propValue: string,
    isValid: boolean,
  ): Promise<void> {
    if (!bundle || bundle.status === SubmissionStatuses.SUBMITTED) return;

    let newBundle = { ...bundle } as SubmissionBundle;

    if (propName === 'name') {
      newBundle.name = propValue;
      setNameError(!isValid);
    } else if (propName === 'internalRecipients') {
      let internalRecipients: string[] = [];

      if (propValue) internalRecipients = JSON.parse(propValue) as string[];
      newBundle.internalRecipients = internalRecipients;
      setSubmissionError('');
    } else if (propName === 'externalOrgId') {
      if (propValue) {
        let valObj = JSON.parse(propValue) as MongoIdSqlId;
        newBundle.externalOrgId = valObj.SQLServerId;
      } else newBundle.externalOrgId = '';

      setExternalOrgValue(propValue);
    } else if (propName === 'externalRecipients') {
      let externalRecipients: string[] = [];

      if (propValue) externalRecipients = JSON.parse(propValue) as string[];
      newBundle.externalRecipients = externalRecipients;
      setSubmissionError('');
    } else if (propName === 'auditSubmissionId') {
      if (propValue) newBundle.auditSubmissionId = new ObjectId(propValue);
      else newBundle.auditSubmissionId = undefined;
      setSubmissionError('');
    } else if (propName === 'isAudit') {
      if (propValue == 'Yes') newBundle.isAudit = true;
      else {
        newBundle.isAudit = false;
        setAuditError('');
        newBundle.auditSubmissionId = undefined;
      }
    } else if (propName === 'submissions') {
      setDocumentsError('');

      let realmBundle = (await getSubmissionBundles()).find(s =>
        s._id.equals(props.bundleId!),
      );

      if (realmBundle) newBundle = realmBundle;
    } else if (propName === 'attachments') {
      setDocumentsError('');

      newBundle.bundleAttachments = propValue;
      if (!newBundle.SQLServerId) newBundle.SQLServerId = SQLServerId;
    }

    setBundle(newBundle);

    if (JSON.stringify(newBundle) !== JSON.stringify(bundle)) {
      newBundle.dataHubVersion = Math.round(Date.now() / 1000);
      upsertSubmissionBundle(newBundle);
    }
  }

  const copyAuditSubmission = (
    auditSubmission: Submission,
  ): SubmissionBundle_submissions => {
    let auditSubmissionAnswer = {
      submissionId: bundle?.auditSubmissionId,
      answers: auditSubmission.answers.map(a => ({
        answer: a.answer,
        controlId: a.controlId,
        controlTypeId: a.controlTypeId,
      })),
      metadataJSON: auditSubmission.metadataJSON,
      templateType: {
        _id: auditSubmission.templateType._id,
        name: auditSubmission.templateType.name,
      },
      templateVersion: {
        _id: auditSubmission.templateVersion._id,
        name: auditSubmission.templateVersion.name,
      },
      updatedBy: auditSubmission.updatedBy,
      updateDateTimeStamp: new Date(),
    } as SubmissionBundle_submissions;

    return auditSubmissionAnswer;
  };

  const onSubmit = async () => {
    setShowErrors(true);

    let auditErrorFlag = false;
    if (bundle && bundle.isAudit) {
      let submission = submissions.find(x =>
        x._id.equals(bundle.auditSubmissionId ?? ''),
      );
      if (
        !submission ||
        submission.submissionStatus.name != SubmissionStatuses.SUBMITTED
      )
        auditErrorFlag = true;
    }

    if (auditErrorFlag) setAuditError('Complete Audit Header required');
    else setAuditError('');

    if (
      nameError ||
      auditErrorFlag ||
      !bundle ||
      bundle.status === SubmissionStatuses.SUBMITTED
    )
      return;

    if (
      (!bundle.internalRecipients || bundle.internalRecipients?.length == 0) &&
      (!bundle.externalRecipients || bundle.externalRecipients?.length == 0)
    ) {
      setSubmissionError('At least one recipient is required for submission');
      return;
    }

    if (
      (!bundle.submissions || bundle.submissions?.length == 0) &&
      (!bundle.bundleAttachments ||
        bundle.bundleAttachments == '' ||
        bundle.bundleAttachments == '[]')
    ) {
      setDocumentsError(
        'You need to add a document or an attachment for submission',
      );
      return;
    }

    // Don't submit if external org has been selected and ext recipients is empty
    if (
      bundle.externalOrgId &&
      (!bundle.externalRecipients || bundle.externalRecipients.length === 0)
    )
      return;

    if (bundle.isAudit) {
      if (bundle.auditSubmissionId) {
        let auditSubmission = await getSubmission(bundle.auditSubmissionId);

        //Check it exists / submitted
        if (
          !auditSubmission ||
          auditSubmission.submissionStatus.name !== SubmissionStatuses.SUBMITTED
        ) {
          setAuditError('Complete Audit header required');
          return;
        }
      } else {
        setAuditError('Complete Audit header required');
        return;
      }
    }

    setShowConfirmModal(true);
  };

  const submitBundle = async () => {
    setShowConfirmModal(false);
    setIsLoading(true);

    try {
      //Copy audit submission so external users can view it
      if (bundle && bundle.auditSubmissionId && bundle.isAudit) {
        let auditSubmission = await getSubmission(bundle.auditSubmissionId);
        let auditSubmissionCopy = copyAuditSubmission(auditSubmission!);
        bundle.submissions?.push(auditSubmissionCopy);
        await upsertSubmissionBundle(bundle);
      }

      let emailStatus = await sendSubmissionEmail();
      if (!emailStatus) {
        setIsLoading(false);
        return;
      }

      let newBundle = { ...bundle } as SubmissionBundle;
      newBundle.status = SubmissionStatuses.SUBMITTED;
      newBundle.submitDateTimeStamp = new Date();
      await upsertSubmissionBundle(newBundle);

      toast.show('Bundle submitted successfully', {
        type: 'success',
      });
      goBack();
    } catch (error) {
      toast.show('Submission Bundle has failed, please try again later', {
        type: 'danger',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onClose = async () => {
    // If bundle is empty, then delete it
    if (
      bundle &&
      !bundle.name &&
      (!bundle.internalRecipients || bundle.internalRecipients.length === 0) &&
      !bundle.externalOrgId &&
      (!bundle.externalRecipients || bundle.externalRecipients.length === 0)
    )
      await deleteSubmissionBundle(bundle._id);

    goBack();
  };

  async function getDocumentsAndAttachmentsTitles(): Promise<string[]> {
    let docTitles: string[] = [];

    let filteredTemplates = await getTemplates();
    let filteredVersions = await getTemplateVersions();
    let submissionTrainings = await getSubmissionTrainings();

    bundle!.submissions?.forEach(s => {
      let version = filteredVersions.find(v =>
        v._id.equals(s.templateVersion?._id ?? ''),
      );
      let template = filteredTemplates.find(t =>
        t._id.equals(version?.templateId ?? ''),
      );
      let title = template?.name ?? '';

      if (s.metadataJSON) {
        let meta = JSON.parse(s.metadataJSON);
        if (meta[METADATA_KEYS.TITLE]) title = meta[METADATA_KEYS.TITLE];
      }

      if (template) {
        if (s.templateType?.name === 'Training Breakdown') {
          let training = submissionTrainings.find(
            t => s.submissionId && t._id.equals(s.submissionId),
          );

          if (training && training.trainingType) {
            let category = training.trainingType.name
              ? 'Training'
              : 'Certificate';
            title =
              (category === 'Training'
                ? training.trainingType.name
                : training.certificateType.name) +
              ' - ' +
              training.person.firstName +
              ' ' +
              training.person.lastName;
          }
        }
      }

      docTitles.push(title);
    });

    if (bundle!.bundleAttachments) {
      try {
        let attachmentsArray = JSON.parse(
          bundle!.bundleAttachments,
        ) as Array<DynamicAttachment>;

        attachmentsArray.forEach(a => {
          if (a.name) docTitles.push(a.name);
        });
      } catch (error) {}
    }

    return docTitles;
  }

  const sendSubmissionEmail = async (): Promise<boolean> => {
    let submissionEmailUrl = process.env.REACT_APP_SUBMISSION_EMAIL_URL;
    let user = getRealmApp().currentUser;
    if (!user) return false;

    let org = user.customData['organisation'] as any;
    let emailInternalRecipients = (await getPeople({}))
      .filter(
        x => x.email && bundle?.internalRecipients?.indexOf(x.email) != -1,
      )
      .map(y => ({
        Name: y.firstName + ' ' + y.lastName,
        Email: y.email,
      }));

    let emailExternalRecipients = (await getOrgRecipients())
      .filter(
        x =>
          bundle?.externalRecipients &&
          x.email &&
          bundle?.externalRecipients?.indexOf(x.email) != -1,
      )
      .map(y => ({
        Name: y.firstName + ' ' + y.lastName,
        Email: y.email,
      }));

    let config: AxiosRequestConfig = {};
    config.data = {
      OrgName: org.name,
      OrgId: parseInt(org.idstring),
      ExternalOrgId:
        bundle?.externalOrgId != null ? parseInt(bundle.externalOrgId) : null,
      InternalRecipients: emailInternalRecipients,
      ExternalRecipients: emailExternalRecipients,
      SubmitterName: user.customData['name'],
      SubmitterEmail: user.customData['email'],
      ReportTitles: await getDocumentsAndAttachmentsTitles(),
      SubmissionId: bundle!._id!.toHexString(),
      SubmitTitle: bundle!.name,
      SubmitDate: bundle!.createDateTimeStamp,
    };
    config.method = 'POST';
    config.url = submissionEmailUrl;
    config.headers = { 'Content-Type': 'application/json' };
    let apiService = new ApiService(() => {});
    let response = await apiService
      .sendRequest<any>(config)
      .then(
        (response: any) => {
          return true;
        },
        error => {
          console.log(error);
          return false;
        },
      )
      .catch(function (error) {
        return false;
      });

    return response;
  };

  const renderConfirmModal = (): React.ReactElement => {
    return (
      <Modal
        visible={showConfirmModal}
        transparent={true}
        statusBarTranslucent={true}
        animationType="fade">
        <View style={ControlsStyleSheet.modalBackground}>
          <View
            style={[ControlsStyleSheet.groupSelectorModal, { height: 'auto' }]}>
            <View style={ControlsStyleSheet.groupSelectorModalBar}>
              <Text
                style={[
                  ControlsStyleSheet.groupSelectorModalTitle,
                  { fontSize: 15 },
                ]}>
                Document Bundle Submission Confirmation
              </Text>
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.groupSelectorModalClose,
                  pressed && {
                    backgroundColor: Colors.darkGreenTransparent,
                    borderRadius: 24,
                  },
                ]}
                onPress={() => setShowConfirmModal(false)}>
                <Icon icon={'close'} color={Colors.darkestGreen} size={24} />
              </Pressable>
            </View>
            <ScrollView
              keyboardShouldPersistTaps="handled"
              style={{ margin: 20 }}>
              <View style={{}}>
                <Text
                  style={[
                    ControlsStyleSheet.label,
                    {
                      fontSize: 15,
                      textTransform: 'none',
                    },
                  ]}>
                  You are submitting a document bundle to internal and external
                  recipients. Your recipients will be notified by email and they
                  will then be able to view the documents in your submission.
                  Once this bundle has been submitted it cannot be deleted and
                  the documents it contains cannot be modified.
                </Text>
                <Text
                  style={[
                    ControlsStyleSheet.label,
                    { fontSize: 15, textTransform: 'none' },
                  ]}>
                  Do you want to submit this document bundle now?
                </Text>
              </View>
            </ScrollView>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingVertical: 20,
              }}>
              <Pressable
                style={({ pressed }) => [
                  CommonStyleSheet.whiteButton,
                  { alignSelf: 'center', marginTop: 0, marginBottom: 20 },
                  pressed && {
                    opacity: 0.8,
                  },
                ]}
                onPress={() => setShowConfirmModal(false)}>
                <Text style={CommonStyleSheet.whiteButtonText}>No</Text>
              </Pressable>
              <Pressable
                style={({ pressed }) => [
                  CommonStyleSheet.greenButton,
                  { alignSelf: 'center', marginTop: 0, marginBottom: 20 },
                  pressed && {
                    opacity: 0.8,
                  },
                ]}
                onPress={() => submitBundle()}>
                <Text style={CommonStyleSheet.greenButtonText}>Yes</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>
    );
  };

  const renderPDFModal = (): React.ReactElement => {
    if (!bundle) return <></>;

    let submissionsInBundle: {
      id: string;
      type: 'submission' | 'submissionTraining';
    }[] = [];

    if (
      bundle.isAudit &&
      bundle.auditSubmissionId &&
      !bundle.submissions?.some(x =>
        x.submissionId!.equals(bundle.auditSubmissionId!),
      )
    )
      submissionsInBundle.push({
        id: bundle.auditSubmissionId.toHexString(),
        type: 'submission',
      });

    if (bundle.submissions)
      bundle.submissions.forEach(s => {
        if (s.submissionId) {
          submissionsInBundle.push({
            id: s.submissionId.toHexString(),
            type:
              s.templateType?.name == TemplateTypes.TRAINING_BREAKDOWN
                ? 'submissionTraining'
                : 'submission',
          });
        }
      });

    return (
      <Modal
        visible={isShowingPDFModal}
        transparent={true}
        statusBarTranslucent={true}
        animationType="fade">
        <View style={ControlsStyleSheet.modalBackground}>
          <View style={ControlsStyleSheet.groupSelectorModal}>
            <View style={ControlsStyleSheet.groupSelectorModalBar}>
              <Text style={ControlsStyleSheet.groupSelectorModalTitle}>
                Download Documents
              </Text>
              <Pressable
                style={({ pressed }) => [
                  ControlsStyleSheet.groupSelectorModalClose,
                  pressed && {
                    backgroundColor: Colors.darkGreenTransparent,
                    borderRadius: 24,
                  },
                ]}
                onPress={() => setIsShowingPDFModal(false)}>
                <Icon icon={'close'} color={Colors.darkestGreen} size={24} />
              </Pressable>
            </View>
            <ScrollView
              keyboardShouldPersistTaps="handled"
              style={{ marginVertical: 24 }}>
              <View style={ControlsStyleSheet.attachmentsContainer}>
                {isShowingPDFModal && (
                  <>
                    <View style={ControlsStyleSheet.attachmentsItem}>
                      <Icon icon={'file'} color={Colors.green} size={20} />
                      <Pressable
                        style={({ pressed }) => [
                          pressed && {
                            opacity: 0.6,
                          },
                        ]}
                        onPress={() => setAutoDownload(true)}>
                        <Text
                          style={[
                            ControlsStyleSheet.attachmentsText,
                            { textDecorationLine: 'underline' },
                          ]}>
                          Click here to download all documents
                        </Text>
                      </Pressable>
                    </View>
                    <DynamicBundlePDF
                      bundleId={bundle!._id.toHexString()}
                      autoDownload={autoDownload}
                    />
                    {submissionsInBundle.map((s, i) => {
                      return (
                        <DynamicPDF
                          key={s.id}
                          submissionId={s.id}
                          type={s.type}
                          autoDownload={autoDownload}
                          bundleId={bundle!._id.toHexString()}
                        />
                      );
                    })}
                  </>
                )}
              </View>
            </ScrollView>
          </View>
        </View>
      </Modal>
    );
  };

  const renderActionsModal = (): React.ReactElement => {
    return (
      <Modal
        visible={isShowingActionsModal}
        transparent={true}
        statusBarTranslucent={true}
        animationType="fade">
        <Pressable
          style={[
            ControlsStyleSheet.modalBackground,
            {
              //@ts-ignore
              cursor: 'auto',
            },
          ]}
          onPress={() => setIsShowingActionsModal(false)}>
          <View
            style={{
              backgroundColor: Colors.white,
              alignSelf: 'flex-end',
              borderRadius: 20,
              marginTop: 110,
              marginRight: 10,
              paddingHorizontal: 20,
            }}>
            <View style={ControlsStyleSheet.groupSelectorModalBar}>
              <Text style={ControlsStyleSheet.groupSelectorModalTitle}>
                More Actions
              </Text>
            </View>
            <View style={{ marginVertical: 20 }}>
              <Pressable
                style={({ pressed }) => [
                  CommonStyleSheet.greenButton,
                  { alignSelf: 'center', marginTop: 12 },
                  pressed && {
                    opacity: 0.8,
                  },
                ]}
                onPress={showPDFModal}>
                <Text style={CommonStyleSheet.greenButtonText}>Print PDF</Text>
              </Pressable>
            </View>
          </View>
        </Pressable>
      </Modal>
    );
  };

  return (
    <View style={DynamicPageStyleSheet.bodyContainer}>
      <View style={DynamicPageStyleSheet.header}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text style={DynamicPageStyleSheet.headerText}>
            Submission Bundle
          </Text>
          <View style={{ paddingRight: 10, justifyContent: 'flex-start' }}>
            <Pressable
              style={({ pressed }) => [
                pressed && {
                  opacity: 0.4,
                },
              ]}
              onPress={onClose}>
              <Icon icon={'close-circle'} color={Colors.white} size={24} />
            </Pressable>
            <Pressable
              style={({ pressed }) => [
                { marginTop: 24, marginBottom: 30 },
                pressed && {
                  opacity: 0.4,
                },
              ]}
              onPress={() => setIsShowingActionsModal(true)}>
              <Icon icon={'more'} color={Colors.white} size={20} />
            </Pressable>
          </View>
        </View>
      </View>
      <ScrollView style={DynamicPageStyleSheet.body}>
        <View style={DynamicPageStyleSheet.controlsContainer}>
          {bundle && (
            <React.Fragment>
              <DynamicTextBox
                label={'Submission Bundle Title'}
                config={{
                  required: true,
                  maxLength: 100,
                }}
                value={bundle.name}
                onChange={(controlId, controlTypeId, value, isValid) =>
                  onChange('name', value, isValid)
                }
                showError={showErrors}
                disabled={bundle.status === SubmissionStatuses.SUBMITTED}
              />
              <DynamicSegmentedControl
                config={{ segments: [{ name: 'Yes' }, { name: 'No' }] }}
                label="Is this an Audit Submission Bundle?"
                value={bundle.isAudit ? 'Yes' : 'No'}
                disabled={bundle.status === SubmissionStatuses.SUBMITTED}
                onChange={(controlId, controlTypeId, value, isValid) =>
                  onChange('isAudit', value, isValid)
                }
              />
              <DynamicAddRecord
                value={bundle.auditSubmissionId?.toHexString()}
                label={'Audit Header'}
                config={{
                  templateType: TemplateTypes.AUDIT,
                }}
                disabled={bundle.status === SubmissionStatuses.SUBMITTED}
                isExternal={isExternal}
                bundleId={bundle._id?.toHexString()}
                type={FORM_TYPE.SUBMISSION}
                onRecordAdded={submissionId => {
                  onChange('auditSubmissionId', submissionId, true);
                }}
                removeRecord={() => {
                  onChange('auditSubmissionId', '', true);
                }}
                navigation={props.navigation}
                visible={bundle.isAudit}
              />
              <Text style={ControlsStyleSheet.error}>{auditError}</Text>
              {isExternal ? (
                <>
                  <DynamicAutocomplete
                    label={'Submitted From'}
                    config={{ optionSource: ListDataTypes.ORGANISATIONS }}
                    value={externalOrgValue}
                    showError={showErrors}
                    disabled={true}
                  />
                  <DynamicRecipients
                    label="Recipients"
                    config={{
                      externalOrgId: bundle.externalOrgId,
                    }}
                    value={
                      bundle.externalRecipients
                        ? JSON.stringify(bundle.externalRecipients)
                        : '[]'
                    }
                    showError={showErrors}
                    disabled={true}
                  />
                </>
              ) : (
                <>
                  <DynamicRecipients
                    label="Internal Recipients"
                    value={
                      bundle.internalRecipients
                        ? JSON.stringify(bundle.internalRecipients)
                        : '[]'
                    }
                    onChange={(controlId, controlTypeId, value, isValid) =>
                      onChange('internalRecipients', value, isValid)
                    }
                    showError={showErrors}
                    disabled={bundle.status === SubmissionStatuses.SUBMITTED}
                  />
                  {(canSubmitExternal ||
                    bundle.status === SubmissionStatuses.SUBMITTED) && (
                    <React.Fragment>
                      <DynamicAutocomplete
                        label={'External Organisation'}
                        config={{ optionSource: ListDataTypes.ORGANISATIONS }}
                        value={externalOrgValue}
                        onChange={(controlId, controlTypeId, value, isValid) =>
                          onChange('externalOrgId', value, isValid)
                        }
                        showError={showErrors}
                        disabled={
                          bundle.status === SubmissionStatuses.SUBMITTED
                        }
                      />
                      {bundle.externalOrgId && (
                        <DynamicRecipients
                          label="External Recipients"
                          config={{
                            externalOrgId: bundle.externalOrgId,
                            required: true,
                          }}
                          value={
                            bundle.externalRecipients
                              ? JSON.stringify(bundle.externalRecipients)
                              : '[]'
                          }
                          onChange={(
                            controlId,
                            controlTypeId,
                            value,
                            isValid,
                          ) => onChange('externalRecipients', value, isValid)}
                          showError={showErrors}
                          disabled={
                            bundle.status === SubmissionStatuses.SUBMITTED
                          }
                        />
                      )}
                    </React.Fragment>
                  )}
                </>
              )}
              <DynamicBundleLinkRecord
                navigation={props.navigation}
                bundleId={new ObjectId(props.bundleId)}
                onChange={(controlId, controlTypeId, value, isValid) => {
                  onChange('submissions', value, isValid);
                }}
                showError={showErrors}
                disabled={bundle.status === SubmissionStatuses.SUBMITTED}
              />
              <DynamicAttachments
                submissionId={SQLServerId}
                attachmentsType="submission-bundle"
                config={{
                  mode: 'photos-attachments',
                  allowedTypes: [
                    'application/pdf',
                    'image/png',
                    'image/jpg',
                    'image/jpeg',
                  ],
                  maxAttachments: 6,
                }}
                value={bundle.bundleAttachments}
                onChange={(controlId, controlTypeId, value, isValid) => {
                  onChange('attachments', value, isValid);
                }}
                setIsLoading={setIsLoading}
                showError={showErrors}
                disabled={bundle.status === SubmissionStatuses.SUBMITTED}
              />
              {!isExternal && bundle.status === SubmissionStatuses.DRAFT && (
                <View style={{ padding: 10 }}>
                  <Pressable
                    style={CommonStyleSheet.greenButton}
                    android_ripple={{ color: Colors.whiteTransparent }}
                    onPress={onSubmit}>
                    <Text style={CommonStyleSheet.greenButtonText}>Submit</Text>
                  </Pressable>
                </View>
              )}
            </React.Fragment>
          )}
          {submissionError ? (
            <Text style={[ControlsStyleSheet.error, { alignSelf: 'center' }]}>
              {submissionError}
            </Text>
          ) : null}
          {documentsError ? (
            <Text style={[ControlsStyleSheet.error, { alignSelf: 'center' }]}>
              {documentsError}
            </Text>
          ) : null}
          {isLoading && <LoadingSpinner message="Loading" visible={true} />}
        </View>
      </ScrollView>
      {renderActionsModal()}
      {renderConfirmModal()}
      {renderPDFModal()}
    </View>
  );
};

export default EditSubmissionBundle;
